'use client'
import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import {Container, Row} from 'react-bootstrap';
import 'simplebar-react/dist/simplebar.min.css';
import ContactForm from "@/components/common/forms/ContactForm";
import CloseIconSearch from "@/components/common/ui/icons/CloseIconSearch";
import DestinationForm from "@/components/common/forms/DestinationForm";
import ContactFormModal from "@/components/common/forms/ContactFormModal";
import {useShowSetting} from "@/app/ShowSettingProvider";

const KeyPopUp = ({
                   show,
                   handleClose,
                   no_img,
                   item,
                   title,
                      courseName,
                      schools,
                   subtitle,
                   img,
                      lefttitle
               }) => {


    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one form-modal modal_form_start"
            >
                <Modal.Body>
                    <Container className={'close-icon-modal'}>
                        <Row className={'for-close'} onClick={handleClose}>
                            <div className="modal-close ">
                                <CloseIconSearch/>
                            </div>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <div className="modal-data d-flex">
                                <ContactFormModal leftTitle={lefttitle} subtitle={subtitle} title={title} schools={schools} courseName={courseName}/>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </StyledModal>

    );
};


const StyledModal = styled.section`
    background-color: #F5F5F5;

    .modal-dialog {
        max-width: 100% !important;
    }

    h4 {
        letter-spacing: 1.5px;
    }


`;


export default React.memo(KeyPopUp);
