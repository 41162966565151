"use client";
import React, {useState} from "react";
import styled from "styled-components";
import useContainerOffset from "@/components/hooks/useContainerOffset";
import FeatureBox from "@/components/home/FeatureBox";
import ThemeContainer from "@/components/common/theme/container";
import Button from "@/components/common/ui/Button";
import {text} from "@/styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useGSAP} from "@gsap/react";
import {Img} from "@/components/common/ui/Img";
import KeyPopUp from "@/components/common/forms/KeyPopUP";
import KeyPopUPBannerSlider from "@/components/common/forms/KeyPopUPBannerSlider";

const BannerSlider = ({ data }) => {

    const offset = useContainerOffset('.container')
    const [show, setShow] = useState(false);
    const [showAnim, setAnim] = useState(true);
    gsap.registerPlugin(SplitText, ScrollTrigger);
    useGSAP(() => {

        let data = sessionStorage?.getItem("loader");
        if(data === false){
            setAnim(true)
        }else{
            setAnim(false)
        }

        gsap.utils.toArray('.split-title').forEach((item, i) => {
            const originalHTML = item.innerHTML;
            const originalLetterSpacing = window.getComputedStyle(item).letterSpacing;

            const parentSplit = new SplitText(item, {
                linesClass: "split-parent",
            });

            const childSplit = new SplitText(item, {
                type: "lines, chars",
                linesClass: "split-child",
            });


            gsap.from(parentSplit.lines, {
                duration: 1,
                yPercent: 100,
                stagger: 0.01,
                delay: data === false ? 0.5 : 2.5
            });

            // If you want to clean up the SplitText instance when the component unmounts:
            return () => {
                parentSplit.revert();
            };
        });
        gsap.to('.hide-button-main',{
            duration: 1,
            opacity:1,
            delay: data === false ? 0.5 : 2.5
        } )
    },[null]);



    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };


  return (
      <>
          <StyledComponent
              offset={offset}
              className={"banner-slider"}
          >
              <Img parallax={true} parallaxSpeed={'1.2'} alt={'Maximus Education and Migration'} src={data?.images?.list?.[0]?.full_path}/>
              <ThemeContainer  colProps={{md: 8}}>
                  <div className="hide-relative">
                      {
                          data?.section_data?.description &&
                          <h1 className={showAnim === true ? 'split-up' :'split-title'}>{reactHtmlParser(data?.section_data?.description)}</h1>

                      }
                  </div>
                  <div className={'hide-button-main'}>
                      <Button margin={'40px 0 0'} href={'javascript:void(0)'} onClick={handleShow}  height={45} borderRadius={25} text={data?.section_data?.subtitle ? data?.section_data?.subtitle :'Get Consultation'}/>

                  </div>

              </ThemeContainer>
          </StyledComponent>

          {
              data?.posts &&
              <FeatureBox data={data?.posts}/>

          }
          <KeyPopUPBannerSlider lefttitle={data?.section_data?.counter_title} subtitle={data?.section?.data?.counter} show={show} handleClose={handleClose}/>

      </>
  );
};

const StyledComponent = styled.section`
    height: 85svh;
    position: relative;
    background: ${text};

    .hide-relative{
        position: relative;
        overflow: hidden;
    }
    .hide-button-main{
        opacity: 0;
    }
    &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(54, 50, 49, 0), #363231);
        min-height: 300px;
        content: '';
    }

    @keyframes reveal {
        0% {
            transform: translate(0, 100%);
        }
        100% {
            transform: translate(0, 0);
        }
    }

    .container {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        overflow: hidden;

        h1 {
            letter-spacing: 1.6px;
            line-height: 92px;
            font-size: 80px;
            color: white;
            display: block;
            overflow: hidden;

            strong {
                font-weight: 500;
            }

            @media (max-width: 1440px) {
                letter-spacing: -0.98px;
                line-height: 68px;
                font-size: 65px;
            }
            @media (max-width: 767px) {
                font-size: 48px;
                line-height: 52px;
                letter-spacing: -0.96px;
            }
        }

        @media (max-width: 1280px) and (min-width: 768px) {
            top: 60%;
        }
        @media (max-width: 767px) {
            top: 200px;
            transform: none;
        }
    }


    @media (max-width: 767px) {
        height: 90svh;
    }

    
    @media(max-width: 1366px) and (min-width: 1024px){
        .container{
            top: 60%;
        }
    }
`;

export default React.memo(BannerSlider);
