"use client";
import React from "react";
import styled from "styled-components";
import {Col} from "react-bootstrap";
import {text} from "@/styles/globalStyleVars";
import Serviceitem from "@/components/common/Serviceitem";
import useDeviceTypeMobile from "@/components/hooks/useDeviceTypeMobile";
import ThemeRow from "@/components/common/theme/row";

const FeatureBox = ({data}) => {

    const isMobile = useDeviceTypeMobile();
    return (
        <StyledComponent>
            <ThemeRow>
                {
                    data?.list && data?.list?.length > 0 &&
                    data?.list?.map((e,index) => {
                        if(isMobile){
                            return(
                                <Col md={4} key={index}>
                                    <Serviceitem title={e?.data?.title} link={e?.data?.subtitle} description={e?.data?.description} tickerLabel={e?.data?.short_desc} />
                                </Col>
                            )
                        }else{
                            return(
                                <Col md={4} data-speed={index === 0 ? 1.2 : index === 1 ? 1.1 : index === 2 ? 1.3 : 1.2}>
                                    <Serviceitem title={e?.data?.title} link={e?.data?.subtitle} description={e?.data?.description} tickerLabel={e?.data?.short_desc} />
                                </Col>
                            )
                        }

                    })
                }
            </ThemeRow>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: ${text};
    margin-top: -1px;
    position: relative;
    padding-bottom: 200px;
    
    @media(max-width: 992px){
        .col-md-4{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    
    @media(max-width: 767px){
        padding-bottom: 120px;
        
        .col-md-4{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
`;

export default React.memo(FeatureBox);
