"use client";
import React, {useEffect, useState} from "react";
import BannerSlider from "@/components/home/BannerSlider";
import ContactForm from "@/components/common/forms/ContactForm";
import Dream from "@/components/home/Dream";
import HomeAbout from "@/components/home/HomeAbout";
import Testimonial from "@/components/home/Testimonial";
import NewsTicker from "@/components/common/partner-ticker/PartnerTicker";
import {FadeUp, ParallaxX, ParallaxXR, ParallaxXX, splitLeft, SplitUp} from "@/components/common/animations/TextAnimations";
import PartnerTicker from "@/components/common/partner-ticker/PartnerTicker";
import {useShowSetting} from "@/app/ShowSettingProvider";




const SectionRenderer = ({item, index}) => {
        const showSetting = useShowSetting();

    switch (item?.section_data?.template) {
        case 'slider_template':
            return <BannerSlider data={item} key={index}/>
        case 'maximax_form':
            return <ContactForm showSetting={showSetting} margin data={item} key={index}/>;
        case 'discover_destination':
            return <Dream data={item} key={index}/>;
        case 'home_about_max':
            return <HomeAbout data={item} key={index}/>;
        case 'testimonial_section':
            return <Testimonial data={item} key={index}/>;
        case 'news_ticker':
            return <PartnerTicker data={item} key={index}/>;
        default:
            return null;
    }
};

export default React.memo(SectionRenderer);
