import React from 'react';
import styled from 'styled-components';
import reactHtmlParser from "react-html-parser";


const PartnersIcon = ({data}) => {
  return (
      <>
          <svg xmlns="http://www.w3.org/2000/svg" width="130" height="36" viewBox="0 0 130 36">
              <g id="Group_25757" data-name="Group 25757" transform="translate(-183 -5675)">
                  <path id="Path_9394" data-name="Path 9394" d="M130,36H0A18,18,0,0,0,18,18V8a8,8,0,0,1,8-8h78a8,8,0,0,1,8,8V18a18,18,0,0,0,18,18" transform="translate(183 5675)" fill="#f8f6f5"/>
                  <text id="Our_Partners" data-name="Our PartnersIcon" transform="translate(213 5697)" fill="#122130" font-size="12" font-family="Graphik-Regular, Graphik" letter-spacing="-0.01em">
                      <tspan x="0" y="0">{reactHtmlParser(data)}</tspan>
                  </text>
              </g>
          </svg>
      </>
  );
};


export default React.memo(PartnersIcon);
