import React from 'react';
import styled from 'styled-components';

/**
 * Hamburger Icon component
 * Pass the props with your data to the Hamburger Icon.
 **/
const DreamBg = () => {
  return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" width="966.081" height="966.081" viewBox="0 0 966.081 966.081">
          <defs>
            <linearGradient id="linear-gradient" x1="0.934" x2="0.163" y2="0.908" gradientUnits="objectBoundingBox">
              <stop offset="0" stopColor="#a7a19f"/>
              <stop offset="1" stopColor="#363231" stopOpacity="0"/>
            </linearGradient>
          </defs>
          <path id="background-vector" d="M966.081,0C966.081,533.543,533.543,966.081,0,966.081V778.947c105.24,0,207.228-20.561,303.134-61.146A776.5,776.5,0,0,0,550.807,550.807,776.5,776.5,0,0,0,717.8,303.134C758.385,207.228,778.947,105.24,778.947,0Z" transform="translate(966.081) rotate(90)" opacity="0.3" fill="url(#linear-gradient)"/>
        </svg>
      </>
  );
};



export default React.memo(DreamBg);
