'use client'
import React from 'react';
import styled from "styled-components";
import {Transition, white} from "@/styles/globalStyleVars";
import Link from "next/link";
import reactHtmlParser from "react-html-parser";


const ServiceItem = ({title, description, link, buttonLabel, offset, tickerLabel}) => {


    return (
        <StyledServicesSingle offset={offset} className='serviceSingle'  >
            <Link  prefetch={true}  href={link ? link: '#'}></Link>
            <h5 className={'split-up'}>{title ? reactHtmlParser(title) : 'Discover your dream courses'}</h5>
            <p className={'split-up'}>{description ? reactHtmlParser(description) : 'Explore our wide range of courses tailored to your academic and career aspirations.'}</p>
            <div className="icon">
                <span>{buttonLabel ? buttonLabel : 'Explore'}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <g id="Button" transform="translate(11553 16554)">
                        <g id="Ellipse_449" data-name="Ellipse 449" transform="translate(-11553 -16554)" fill="none" stroke="#fff" strokeWidth="1">
                            <circle cx="20" cy="20" r="20" stroke="none"/>
                            <circle cx="20" cy="20" r="19.5" fill="none"/>
                        </g>
                        <circle id="Ellipse_450" data-name="Ellipse 450" cx="20" cy="20" r="0" transform="translate(-11553 -16554)" fill="#fb5400"/>
                        <g id="Arrow" transform="translate(-12448.703 -16285.806) rotate(-45)">
                            <g id="Group_4823" data-name="Group 4823" transform="translate(823 467)">
                                <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                            </g>
                            <line id="Line_11" data-name="Line 11" x2="10" transform="translate(818 472)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div className="ticker">
                <p>{tickerLabel ? reactHtmlParser(tickerLabel) : 'Courses'}</p>
            </div>
        </StyledServicesSingle>
    );
};

const StyledServicesSingle = styled.div`
    background-color: rgba(255, 255 ,255 , 7%);
    backdrop-filter: blur(5px) brightness(0.5) contrast(50%);
    padding: 60px 40px 120px;
    border-radius: 8px;
    position: relative;
    height: 100%;
    
    .ticker{
        position: absolute;
        right: 0;
        top: 60px;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        background: #FB5400;
        padding: 15px 8px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        
        
        p{
            font-size: 12px;
            letter-spacing: -0.12px;
            line-height: 16px;
            color: ${white};
            font-weight: 400;
        }
    }

    a {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 3;
    }

    h5 {
        color: ${white};
        margin-bottom: 20px;
    }

    p {
        color: ${white};
    }

    .icon {
        position: absolute;
        right: 40px;
        bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            letter-spacing: -0.12px;
            font-size: 12px;
            line-height: 16px;
            color: ${white};
            font-weight: 400;
            margin-right: 20px;
        }

        svg {
            transition: 0.7s all ${Transition};
            transform: rotate(45deg);

            #Ellipse_450 {
                transition: 0.7s all ${Transition};

            }
        }
    }


    &:hover {
        .icon {
            svg {
                transform: rotate(0);
                
                #Ellipse_450{
                    r: 20px;

                }
            }
        }
    }

    @media (min-width: 1536px) {
        padding: 60px 40px 160px;

    }
    @media (max-width: 767px) {

    }
`;

export default React.memo(ServiceItem);
