"use client";
import React from "react";
import styled from "styled-components";
import {Col} from "react-bootstrap";
import {softWhite, white} from "@/styles/globalStyleVars";
import DestinationSingle from "@/components/common/DestinationSingle";
import useDeviceTypeMobile from "@/components/hooks/useDeviceTypeMobile";
import reactHtmlParser from "react-html-parser";
import ThemeRow from "@/components/common/theme/row";
import DreamBg from "@/components/common/ui/icons/DreamBg";

const FeatureBox = ({data}) => {

    const isMobile = useDeviceTypeMobile()
    const thumb = data?.images?.list?.find((f) => f?.background === 'on')



    return (
        <StyledComponent thumb={thumb?.full_path}>
            <div className="fixed-icon" data-speed={'1.1'}>
                <DreamBg/>
            </div>
            <ThemeRow>
                <Col className={'left d-flex '} md={4}>
                    <h3 data-speed={'1.2'}>Discover</h3>
                    <h3 data-speed={'1.3'}>Destination</h3>
                </Col>
                <Col className={'right'} md={8}>
                    {
                        !isMobile ?
                            <div className="d-flex justify-content-between iteam-wrapper">
                                <div data-speed={'1.2'} className="single-item d-flex">
                                    {
                                        data?.section_data?.subtitle &&
                                        <h4>{reactHtmlParser(data?.section_data?.subtitle)}</h4>
                                    }
                                    {
                                        data?.section_data?.short_desc &&
                                        <p>{reactHtmlParser(data?.section_data?.short_desc)}</p>

                                    }
                                    <div className="left-single">
                                        <DestinationSingle link={data?.posts?.list?.[0]?.data?.subtitle} description={data?.posts?.list?.[0]?.data?.short_desc} background={data?.posts?.list?.[0]?.images?.find((f) => f?.banner === 'on')?.full_path} logo={data?.posts?.list?.[0]?.images?.find((f) => f?.thumb === 'on')?.full_path} title={data?.posts?.list?.[0]?.data?.title}/>
                                    </div>
                                </div>
                                <div data-speed={'0.95'} className="single-item">
                                    <div data-speed={'clamp(1.2)'} className="single-item-single">
                                        <DestinationSingle link={data?.posts?.list?.[1]?.data?.subtitle} description={data?.posts?.list?.[1]?.data?.short_desc} background={data?.posts?.list?.[1]?.images?.find((f) => f?.banner === 'on')?.full_path} title={data?.posts?.list?.[1]?.data?.title} logo={data?.posts?.list?.[1]?.images?.find((f) => f?.thumb === 'on')?.full_path}/>
                                    </div>
                                    <div data-speed={'1.3'} className="single-item-single">
                                        <DestinationSingle link={data?.posts?.list?.[2]?.data?.subtitle} description={data?.posts?.list?.[2]?.data?.short_desc} background={data?.posts?.list?.[2]?.images?.find((f) => f?.banner === 'on')?.full_path} title={data?.posts?.list?.[2]?.data?.title} logo={data?.posts?.list?.[2]?.images?.find((f) => f?.thumb === 'on')?.full_path}/>

                                    </div>
                                </div>
                            </div>

                            :
                            <div className="d-flex justify-content-between iteam-wrapper">
                                <div className="single-item d-flex">
                                    {
                                        data?.section_data?.subtitle &&
                                        <h4>{reactHtmlParser(data?.section_data?.subtitle)}</h4>
                                    }
                                    {
                                        data?.section_data?.short_desc &&
                                        <p>{reactHtmlParser(data?.section_data?.short_desc)}</p>

                                    }
                                    <div className="single-item-single">
                                        <DestinationSingle link={data?.posts?.list?.[1]?.data?.subtitle} description={data?.posts?.list?.[1]?.data?.short_desc} background={data?.posts?.list?.[1]?.images?.find((f) => f?.banner === 'on')?.full_path} title={data?.posts?.list?.[1]?.data?.title} logo={data?.posts?.list?.[1]?.images?.find((f) => f?.thumb === 'on')?.full_path}/>
                                    </div>

                                </div>
                                <div className="single-item">
                                    <div className="left-single">
                                        <DestinationSingle link={data?.posts?.list?.[0]?.data?.subtitle} description={data?.posts?.list?.[0]?.data?.short_desc} background={data?.posts?.list?.[0]?.images?.find((f) => f?.banner === 'on')?.full_path} logo={data?.posts?.list?.[0]?.images?.find((f) => f?.thumb === 'on')?.full_path} title={data?.posts?.list?.[0]?.data?.title}/>
                                    </div>
                                    <div className="single-item-single">
                                        <DestinationSingle link={data?.posts?.list?.[2]?.data?.subtitle} description={data?.posts?.list?.[2]?.data?.short_desc} background={data?.posts?.list?.[2]?.images?.find((f) => f?.banner === 'on')?.full_path} title={data?.posts?.list?.[2]?.data?.title} logo={data?.posts?.list?.[2]?.images?.find((f) => f?.thumb === 'on')?.full_path}/>
                                    </div>
                                </div>
                            </div>
                    }
                </Col>
            </ThemeRow>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: ${white};
    position: relative;
    padding-top: 200px;
    padding-bottom: 200px;
    background-image: url(${(props) => props.thumb || ""});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    margin-top: -1px;
    @media (max-width: 767px) {
        overflow: hidden;
    }
    .fixed-icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    h4 {
        font-weight: 400;

        strong {
            font-weight: 500;
        }
    }

    .left {
        justify-content: flex-start;
        flex-direction: row-reverse;
        align-items: center;
        gap: 30px;
        transform: rotate(180deg);

        h3 {
            letter-spacing: -2.4px;
            font-size: 120px;
            line-height: 88px;
            font-weight: 600;
            color: ${softWhite};
            writing-mode: vertical-lr;
        }
    }

    .iteam-wrapper {
        flex-wrap: wrap;
        gap: 15px;

        .single-item {
            flex: 0 0 calc(50% - 30px);
            max-width: calc(50% - 30px);

            .single-item-single {
                margin-bottom: 80px;

                &:first-child {
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.d-flex {
                justify-content: center;
                flex-direction: column;

                h4 {
                    margin-bottom: 30px;
                }

                p {
                    margin-bottom: 60px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .left {
            display: none !important;
        }

        .right {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media(max-width: 767px){
        padding: 120px 0;
        .left-single{
            margin-bottom: 25px;
        }
        .right {
            flex: 0 0 100%;
            .single-item{
                flex: 0 0 100%;
                max-width: 100%;
                .single-item-single{
                    &:first-child{
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
`;

export default React.memo(FeatureBox);
